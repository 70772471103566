<template>
  <div>
    <b-overlay :show="loading" :opacity="0" no-center variant="transparent" rounded="sm">
      <template #overlay>
        <loading-spinner />
      </template>
      <b-row v-if="!users || users.length < 1">
        <discover-no-user>
          <h3 class="color-black">
            {{ $t('Sorry, no one has liked you yet') }}
          </h3>
        </discover-no-user>
      </b-row>
      <b-row>
        <user-card v-for="user in mergeFavoriteUsers" :key="user.id" :user="user" @setLoading="setLoading($event)"/>
      </b-row>
    </b-overlay>
  </div>
</template>

<script>
import { BOverlay, BRow } from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import UserCard from '@/components/UserCard'
import DiscoverNoUser from '@/components/DiscoverNoUser'
import { throwDefaultError } from "@/helpers/helpers";

export default {
  name: 'interaction-reverse-favorite-users',
  props: {
    activeTabIndex: {
      type: Number,
      default: 1
    },
    favoriteUsers: {
      type: Array,
      default: []
    }
  },
  components: {
    BRow,
    BOverlay,

    DiscoverNoUser,
    UserCard,
  },
  data() {
    return {
      loading: false,
      scrollOffsetForLoadMore: 100,
      users: [],
      usersLimit: 100,
      usersOffset: 0,
      usersAllLoaded: false,
    }
  },
  created() {
    this.getUsers()

    // Scroll'u dinle
    window.addEventListener('scroll', this.handleScroll)
  },
  destroyed () {
    window.removeEventListener('scroll', this.handleScroll);
  },
  computed: {
    mergeFavoriteUsers() {
      return this.users.map(u => {
        u['isFavorite'] = false
        if (this.favoriteUsers.find(_u => _u.id === u.id)) {
          u['isFavorite'] = true
        }
        return u
      })
    },
  },
  methods: {
    getUsers(clear = true) {
      this.loading = true
      useJwt.getReverseFavorites(this.usersOffset, this.usersLimit)
          .then(response => {
            if (clear) {
              this.users = response.data.body.users
              this.usersOffset = response.data.body.users.length
            } else {
              this.users.push(...response.data.body.users)
            }

            // Offset ayarla
            this.usersOffset = response.data.body.offset + response.data.body.limit

            if (this.usersOffset >= response.data.body.totalCount) {
              this.usersAllLoaded = true
            }
          })
          .catch(error => {
            throwDefaultError(this.$toast, error)
          })
          .finally(() => { this.loading = false })
    },
    handleScroll (event) {
      if (this.activeTabIndex === 2) {
        if ((window.innerHeight + window.scrollY + this.scrollOffsetForLoadMore) >= document.body.scrollHeight &&
            !this.usersAllLoaded) {
          this.loadMore()
        }
      }
    },
    loadMore() {
      if (!this.loading) {
        this.getUsers(false)
      }
    },
    setLoading($event) {
      this.loading = $event
    },
  }
}
</script>

<style lang="scss">
/* Set the theme color of the component */
$themeColor: #F9447A;

/* import theme style */
@import '~vue-slider-component/lib/theme/default.scss';
</style>
